@import "variables";

body {
    background-color: $blue;
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.card {
    min-width: 40vh;
}
